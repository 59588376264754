import React, { Component } from "react";

export default class Title extends Component {
  render() {
    return (
      <header>
        <h1 id="site-name">HappylilTreesDesigns</h1>
      </header>
    );
  }
}
